<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>销售管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/promote' }"
          >推广用户</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加推广用户</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-form ref="form" :rules="rules" :model="form" label-width="160px">
      <el-form-item label="选择推广用户：" prop="userId">
        <div class="detail-info">
          <div class="padding-sm">
            <el-select
              v-model="searchType"
              slot="prepend"
              placeholder="选择类型"
              size="mini"
              @change="changeSearch"
            >
              <el-option label="微信昵称" :value="1"></el-option>
              <el-option label="UID" :value="2"></el-option>
            </el-select>

            <el-select
              class="margin-l-sm"
              v-model="studentName"
              size="mini"
              clearable
              filterable
              remote
              reserve-keyword
              @change="selectUser"
              @clear="clearUser"
              placeholder="请输入关键词"
              :remote-method="searchStudent"
              :loading="loading"
            >
              <el-option
                style="height: 40px; line-height: 40px"
                v-for="item in users"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <div
                  style="
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    height: 40px;
                    line-height: 40px;
                  "
                >
                  <span style="margin-right: 10px"> {{ item.id }} </span>
                  <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                  <span style="margin-left: 10px">{{ item.name }}</span>
                </div>
              </el-option>
            </el-select>

            <div
              v-if="JSON.stringify(selectCurrentUser) !== '{}'"
              style="
                margin-top: 12px;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 4px;
                margin: 4px;
              "
            >
              <el-avatar
                size="small"
                :src="selectCurrentUser.avatar_url"
              ></el-avatar>
              <span style="margin-left: 4px">{{ selectCurrentUser.name }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="validateForm('form')">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "template-add",
  data() {
    return {
      form: {
        userId: null,
      },
      searchType: 1,
      loading: false,
      studentName: "",
      users: [],
      selectCurrentUser: {},
      rules: {
        userId: [
          { required: true, message: "请选择推广员", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("promote", ["addLevel","addPromoter"]),
    ...mapActions("user", ["getUserList"]),
    searchStudent(v) {
      console.log("searchStudent");
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = v;
        searchData.pageSize = 1000;
      }
      console.log(searchData);
      if (v === "") {
        this.$message.error("请输入昵称");
        return;
      }
      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
        }
      });
      this.form.userId = id;
    },
    clearUser() {},
    changeSearch(e) {
      console.log("=================");
      console.log(e);
    },
    validateForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.add();
        }
      });
    },
  
    async add() {
      this.loading = true;
      const { res_info } = await this.addPromoter(this.form);
      this.loading = false;
      if (res_info != "ok") return;
      this.$message.success("创建成功");
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.el-form-item {
  display: flex;
  align-items: center;
}
</style>
>
